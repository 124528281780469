<template>
  <div class="page">

    <div class="margin-page">

      <div id="d1" style="display:none;z-index: 1000;position: relative;">
        <ModalAlwaysShow
          tipo='error'
          :messageErr="internalizationContactUs.contactUsErrTimeOutLabel"
          :detailErr="internalizationContactUs.contactUsErrTimeOutMsg"
        />
      </div>

      <div class="titlecss">
        <svg class="imgstyle" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path :fill="fillIconStyle1" fill-rule="evenodd" clip-rule="evenodd" d="M9.85651 7.33797C10.3746 6.63976 10.5604 5.90391 9.97444 5.25438C8.65949 3.4144 7.77515 2.27657 7.22044 1.72861C6.16589 0.686876 4.43112 0.827787 3.51779 1.72772C3.02723 2.21108 2.86116 2.37707 2.35747 2.88868C-0.448184 5.69584 1.26285 11.6301 5.81139 16.1831C10.3589 20.7351 16.2926 22.447 19.1041 19.6339C19.5689 19.1858 19.9624 18.7921 20.2728 18.4641C21.1678 17.518 21.3037 15.8598 20.2669 14.7825C19.7354 14.2302 18.6503 13.3885 16.7329 12.017C16.1457 11.4919 15.4494 11.6057 14.811 12.0245C14.504 12.226 14.2805 12.4298 13.8585 12.8521L13.0923 13.6188C12.9914 13.7198 11.621 13.0335 10.2907 11.7019C8.95972 10.3696 8.2739 8.99913 8.37435 8.89869L9.14112 8.1315C9.27499 7.99749 9.339 7.93261 9.42108 7.84634C9.59209 7.66661 9.73368 7.50351 9.85651 7.33797ZM14.5057 15.033L15.2721 14.2662C15.5044 14.0337 15.6549 13.8908 15.7773 13.7923C17.457 14.9985 18.4297 15.7569 18.8271 16.1698C19.0656 16.4176 19.0286 16.8699 18.8212 17.0891C18.5342 17.3925 18.1613 17.7655 17.7037 18.2069C15.8857 20.0257 11.0959 18.6438 7.22513 14.7692C3.35315 10.8934 1.97188 6.10286 3.7762 4.29756C4.27786 3.78805 4.4368 3.62919 4.92035 3.15273C5.10166 2.97408 5.59552 2.93396 5.81608 3.15184C6.24314 3.57371 7.03534 4.58806 8.20071 6.2114C8.14038 6.2863 8.06503 6.37094 7.97333 6.46732C7.90603 6.53805 7.84996 6.59489 7.72738 6.71759L6.96136 7.48403C5.65821 8.78707 6.76802 11.0048 8.87697 13.1158C10.9845 15.2254 13.203 16.3364 14.5057 15.033Z"/>
          <path :fill="fillIconStyle1" fill-rule="evenodd" clip-rule="evenodd" d="M16.0122 10.156C15.4735 6.74463 13.023 6 11.9999 6V4C13.7839 4 17.2632 5.25537 17.9877 9.84404L16.0122 10.156Z"/>
          <path :fill="fillIconStyle1" fill-rule="evenodd" clip-rule="evenodd" d="M20.0122 10.156C18.9683 3.54463 14.1458 2 11.9999 2V0C14.9067 0 20.7579 2.05537 21.9877 9.84404L20.0122 10.156Z"/>
        </svg>
        {{internalizationContactUs.contactUsTitle}}
      </div>

      <div class="separator"></div>
        
      <div v-for="contact in contactInfo" :key="contact.number">
        
        <hr/>

        <span class="subtitle">

          <svg v-if="contact.imgPath == 'wpp-blank.svg'" class="imgstyle" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :fill="fillIconStyle2" d="M9.51576 0.0120641C4.20576 0.262064 0.0167605 4.65206 0.0327605 9.92906C0.0353911 11.4824 0.407284 13.0128 1.11776 14.3941L0.0597605 19.4951C0.0468514 19.5588 0.0502317 19.6248 0.0695883 19.687C0.088945 19.7491 0.123653 19.8053 0.170504 19.8505C0.217354 19.8956 0.274834 19.9283 0.337628 19.9453C0.400422 19.9624 0.466503 19.9633 0.529761 19.9481L5.56376 18.7641C6.89704 19.4229 8.36071 19.7755 9.84776 19.7961C15.2748 19.8791 19.7988 15.6011 19.9678 10.2161C20.1498 4.44106 15.3508 -0.264936 9.51576 0.011064V0.0120641ZM15.5228 15.3791C14.7972 16.102 13.9362 16.6746 12.989 17.0642C12.0417 17.4537 11.027 17.6525 10.0028 17.6491C8.79777 17.6528 7.60844 17.3761 6.52876 16.8411L5.82776 16.4941L2.74076 17.2201L3.39076 14.0891L3.04476 13.4171C2.48386 12.3298 2.193 11.1235 2.19676 9.90006C2.19676 7.83006 3.00876 5.88306 4.48276 4.42006C5.95196 2.96576 7.93551 2.1497 10.0028 2.14906C12.0888 2.14906 14.0488 2.95506 15.5228 4.41906C16.249 5.1347 16.8253 5.98791 17.2179 6.92883C17.6106 7.86975 17.8118 8.8795 17.8098 9.89906C17.8098 11.9511 16.9848 13.9281 15.5228 15.3791Z"/>
            <path :fill="fillIconStyle2" d="M14.8418 12.0451L12.9108 11.4951C12.7862 11.4593 12.6542 11.4577 12.5288 11.4904C12.4033 11.5231 12.289 11.589 12.1978 11.6811L11.7258 12.1591C11.6281 12.258 11.5034 12.3259 11.3674 12.3544C11.2313 12.3828 11.0899 12.3706 10.9608 12.3191C10.0478 11.9521 8.12576 10.2561 7.63476 9.40706C7.56593 9.28715 7.53449 9.14941 7.54447 9.01151C7.55445 8.8736 7.60539 8.74182 7.69076 8.63306L8.10276 8.10306C8.18208 8.00152 8.23226 7.8803 8.24794 7.75241C8.26362 7.62451 8.2442 7.49476 8.19176 7.37706L7.37976 5.55306C7.33384 5.45112 7.26489 5.36121 7.17834 5.29041C7.0918 5.21961 6.99001 5.16985 6.88098 5.14504C6.77195 5.12023 6.65866 5.12105 6.55 5.14743C6.44134 5.1738 6.34028 5.22503 6.25476 5.29706C5.71576 5.75006 5.07576 6.43706 4.99876 7.20006C4.86176 8.54306 5.44176 10.2361 7.63576 12.2701C10.1708 14.6191 12.2018 14.9301 13.5228 14.6111C14.2728 14.4311 14.8728 13.7081 15.2498 13.1171C15.3101 13.0232 15.3475 12.9165 15.359 12.8055C15.3706 12.6945 15.3559 12.5824 15.3162 12.4781C15.2765 12.3739 15.2129 12.2804 15.1305 12.2051C15.0481 12.1299 14.9492 12.0751 14.8418 12.0451Z"/>
          </svg>

          <svg v-if="contact.imgPath == 'icon.svg'" class="imgstyle" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :fill="fillIconStyle2" fill-rule="evenodd" clip-rule="evenodd" d="M9.85651 6.3379C10.3746 5.63969 10.5604 4.90384 9.97444 4.25431C8.65949 2.41433 7.77515 1.27649 7.22044 0.728537C6.16589 -0.313196 4.43112 -0.172285 3.51779 0.727647C3.02723 1.21101 2.86116 1.377 2.35747 1.88861C-0.448184 4.69577 1.26285 10.63 5.81139 15.183C10.3589 19.735 16.2926 21.4469 19.1041 18.6338C19.5689 18.1858 19.9624 17.792 20.2728 17.464C21.1678 16.518 21.3037 14.8597 20.2669 13.7824C19.7354 13.2301 18.6503 12.3885 16.7329 11.0169C16.1457 10.4918 15.4494 10.6056 14.811 11.0244C14.504 11.2259 14.2805 11.4297 13.8585 11.8521L13.0923 12.6187C12.9914 12.7197 11.621 12.0334 10.2907 10.7018C8.95972 9.36953 8.2739 7.99906 8.37435 7.89862L9.14112 7.13142C9.27499 6.99742 9.339 6.93254 9.42108 6.84627C9.59209 6.66654 9.73368 6.50344 9.85651 6.3379ZM14.5057 14.0329L15.2721 13.2661C15.5044 13.0337 15.6549 12.8907 15.7773 12.7922C17.457 13.9984 18.4297 14.7568 18.8271 15.1697C19.0656 15.4176 19.0286 15.8699 18.8212 16.089C18.5342 16.3924 18.1613 16.7655 17.7037 17.2068C15.8857 19.0256 11.0959 17.6437 7.22513 13.7691C3.35315 9.89332 1.97188 5.10279 3.7762 3.29748C4.27786 2.78798 4.4368 2.62912 4.92035 2.15265C5.10166 1.97401 5.59552 1.93389 5.81608 2.15176C6.24314 2.57364 7.03534 3.58799 8.20071 5.21133C8.14038 5.28623 8.06503 5.37087 7.97333 5.46724C7.90603 5.53798 7.84996 5.59481 7.72738 5.71751L6.96136 6.48396C5.65821 7.787 6.76802 10.0047 8.87697 12.1157C10.9845 14.2253 13.203 15.3364 14.5057 14.0329Z"/>
          </svg>
          
          <svg v-if="contact.imgPath == 'icon-mail.svg'" class="imgstyle" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :fill="fillIconStyle2" fill-rule="evenodd" clip-rule="evenodd" d="M12.9995 5H14.9995V10C14.9995 12.4709 15.189 13 15.9995 13C17.3039 13 17.9995 12.3832 17.9995 10C17.9995 4.71065 15.3466 2 9.99967 2C6.53563 2.00044 3.46541 4.23026 2.3934 7.52426C1.32139 10.8182 2.49117 14.428 5.29145 16.4671C8.09173 18.5062 11.8862 18.5114 14.6921 16.48L15.865 18.1C12.3576 20.6393 7.61449 20.6328 4.11414 18.0839C0.61379 15.5349 -0.848432 11.0228 0.491581 6.90532C1.83159 2.78783 5.66937 0.000544252 9.99955 0C16.4609 0 19.9995 3.6156 19.9995 10C19.9995 13.5449 18.3584 15 15.9995 15C14.6557 15 13.8866 14.5667 13.4644 13.6048C12.5657 14.4688 11.3446 15 9.99955 15C7.23812 15 4.99955 12.7614 4.99955 10C4.99955 7.23858 7.23812 5 9.99955 5C11.1252 5 12.1639 5.37194 12.9995 5.99963V5ZM12.9995 10C12.9995 11.6569 11.6564 13 9.99955 13C8.34269 13 6.99955 11.6569 6.99955 10C6.99955 8.34315 8.34269 7 9.99955 7C11.6564 7 12.9995 8.34315 12.9995 10Z"/>
          </svg>

          {{contact.number}}
        </span>

        <span class="details">

          <template v-if="contact.details != null">
            <span class="det">
              ({{contact.details}})
            </span>
          </template>

          <template v-if="contact.details == null">
            {{contact.details}}
          </template>
          
        </span>

      </div>

      <hr/>
      
    </div>

    <ExceptionModal
      ref="exceptionWarnig"
      tipo='error'
      :messageErr="internalizationContactUs.contactUsErrFetchLabel"
      :detailErr="internalizationContactUs.contactUsErrFetchMsg"
    />
    <ExceptionModal
      ref="exceptionWarnig2"
      tipo='error'
      :messageErr="internalizationContactUs.contactUsErrCommunicationFailLabel"
      :detailErr="internalizationContactUs.contactUsErrCommunicationFailMsg"
    />

  </div>
</template>

<script>
import { getWithExpiry, getInternalization, endSess } from '@/util/util.js';

/* Internalization, used if not use BD to get data 
var internalizationContactUsTmp = {

  "contactUsTitle": "Atendimento",

  "contactUsErrTimeOutLabel": "Tempo Excedido",
  "contactUsErrTimeOutMsg": "Você atingiu o limite de 05 minutos sem executar nenhuma ação.",

  "contactUsErrFetchLabel": "Falha na comunicação.",
  "contactUsErrFetchMsg": "Houve falha na comunicação. Tente novamente mais tarde.",

  "contactUsErrCommunicationFailLabel": "Falha na comunicação.",
  "contactUsErrCommunicationFailMsg": "Houve falha na comunicação. Tente novamente mais tarde.",

};
/* */

export default{
   
  data(){
    return {
      fillIconStyle1: 'var(--attendence-icon1-color)',
      fillIconStyle2: 'var(--attendence-icon2-color)',
      contactInfo:[],
      listJson:[],
      onLine:true,
    }
  },
  methods:{
    checkOnline(){
      
      if(!navigator.onLine){
        endSess('dados')
        this.$refs.exceptionWarnig2.enable();
      }
    },
    defTimeOut(){
      var timeout;

      function refresh(){
        
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          var el = document.getElementById("d1");
          el.style.display = "flex";
        },  300000);

      }
      refresh();
      document.addEventListener('click', refresh);
    }
  },
  created(){

    this.internalizationContactUs = getInternalization();

    const holder = JSON.parse(getWithExpiry('dados'));
    //voltar para not holder
    if(!holder){
      //this.$root.$refs.loadingModal.disable();
      //this.showWarningModal();
      this.$root.$refs.exceptionWarnigFetchingData.enable();
    }
    else{
      setInterval(() => {
        this.checkOnline();
      }, 3000);

      this.defTimeOut();

      const FETCH_TIMEOUT = 30000;
      let didTimeOut = false;
      var urlReq = process.env.VUE_APP_CONSENTS+"/consent/contact";
    
      const promise = new Promise(function(resolve, reject){

        const timeout = setTimeout(function() {
          didTimeOut = true;
          reject(new Error('Request timed out'));
        }, FETCH_TIMEOUT);
          
        fetch(urlReq)
        .then(function(response) {
          // Clear the timeout as cleanup
          clearTimeout(timeout);
          if(!didTimeOut) {
          
            resolve(response.text());
              
          }
        })
        .catch(function(err) {
            
          // Rejection already happened with setTimeout
          if(didTimeOut) return;
          // Reject with error
          reject(err);
          //console.log(err)
        });
      });

      promise.then((response)=> {
      
        const resp = JSON.parse(response);

        /*//if error in mongoDB
        let resp = [];
        const forward = response;

        //eslint-disable-next-line
        let responseTmp = response.replace(/\\\"/g,'').split("{");

        responseTmp.forEach(elem => {
            
            if(elem.includes('iconType')){

              let tmp = {
                iconType: elem.split("iconType: ")[1].split(", item:")[0],
                item: elem.split("item: ")[1].split(", descriptionItem:")[0],
                descriptionItem: elem.split("descriptionItem:")[1].split("}")[0],
              }
              
              resp.push(tmp);
            }
          }
        );
        */

        //console.log(resp);

        resp.forEach(element => {
          let obj = {
              iconType:element.iconType,

              number:element.item,
              details:element.descriptionItem
            }
            this.listJson.push(obj)
        });

        this.listJson.forEach(element => {

          if(element.iconType == 0){
            element.imgPath = 'wpp-blank.svg';
          }
          else if(element.iconType == 1){
            element.imgPath = 'icon.svg';
          }
          else{
            element.imgPath = 'icon-mail.svg';
          }
        
          this.contactInfo.push(element);
        });

      })
      .catch((err) =>{

        // Error: response error, request timeout or runtime error
        this.$root.$refs.loadingModal.disable();
        this.$refs.exceptionWarnig.enable();
        //this.showWarningModal();
        //console.log(err)

      });
    }
  },
  mounted(){
    this.$root.$refs.mainNavbar.showGoBackButton();
  }
}
</script>

<style scoped>
.page{
  height: 100vh;
  background-color: var(--page-background-color);
}
.margin-page{
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 10px;
}
.titlecss{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: var(--attendence-title-color);
}
.separator{
  padding-bottom: 13px;
}
.subtitle{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color:var(--attendence-subtitle-color);
}
.details{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color:var(--attendence-subtitle-color);
  margin-left: 5px;
}
.imgstyle{
  margin-right: 13px;
}
.det{
  margin-left: 0% !important;
}
hr{
  background-color: var(--attendence-hr-color);
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  height: 0.9px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>          